import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import BackButton from "../components/back-button";

import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Grid, Cell } from "baseui/layout-grid";
import { Heading, HeadingLevel } from "baseui/heading";
import { Label2, Paragraph1 } from "baseui/typography";

const newsTemplate = ({
  data: {
    markdownRemark: {
      frontmatter: { title, date, tag, source },
      excerpt,
      html,
    },
  },
}) => {
  const [css] = useStyletron();
  const theme = tag.toLowerCase() === "фрп" ? "frp" : "cmf";
  const styles = css({
    fontSize: "18px",
    fontWeight: "normal",
    lineHeight: "28px",
    color: "#545454",
  });

  return (
    <Layout theme={theme}>
      <SEO title={title} description={excerpt} theme={theme} />
      <Grid>
        <Cell span={[4, 6, 8]}>
          <HeadingLevel>
            <Heading>{title}</Heading>
            <Label2>{date}</Label2>
            <Block className={styles}>
              {/* .innerhtml and themes styles described with global styles in layout.css */}
              <Block
                className={`innerhtml ${theme}`}
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </Block>
            {source && <Label2 paddingBottom="20px">Источник: {source}</Label2>}
            <BackButton title="Назад" />
          </HeadingLevel>
        </Cell>
      </Grid>
    </Layout>
  );
};
export default newsTemplate;

export const query = graphql`
  query($slug: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        date(formatString: "DD MMMM YYYY", locale: "ru")
        tag
        title
        source
      }
      html
      excerpt
    }
  }
`;
